
import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { firebase, firestore } from '@/components/utils/firebase'
import VueClipboard from 'vue-clipboard2'
import '@mdi/font/css/materialdesignicons.css'
import { initializeDatabase } from '../functions/shared/database'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import * as VueGoogleMaps from 'vue2-google-maps'

initializeDatabase(firestore, {
  FieldValue: firebase.firestore.FieldValue
})

Vue.use(VueClipboard)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAqcIMGKErjLyPspfHJ8estdmbvGYkCx8I',
    libraries: 'places',
    region: 'JP',
    language: 'ja'
  }
})

Vue.config.productionTip = false
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
