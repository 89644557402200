import { config, api } from './config'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/functions'

const firebaseApp = firebase.initializeApp(config)

const storage = firebaseApp.storage()
const auth = firebaseApp.auth()
const firestore = firebaseApp.firestore()
const functions = firebaseApp.functions('asia-northeast1')

export default firebaseApp.firestore()
export { firestore, firebase, storage, auth, api, functions }
