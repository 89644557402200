import { firebase } from '@/components/utils/firebase'
import { database } from '../../functions/shared/database'
import { debounce } from '@/components/utils/debounce'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  subscriptionEnder: null,
  isAnonymous: null,
  updateOrdersUnsubscribe: null,
  updateMessageRoomsUnsubscribe: null,
  updateMessagesUnsubscribe: null
})

export const mutations = {
  updateUid (state, value) {
    state.uid = value
  },
  updateUserDisplayName (state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating (state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn (state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut (state, value) {
    state.isSigningOut = value
  },
  updateAuthStatus (state, user) {
    if (user && !state.isLoggedIn) {
      try {
        debounce(this, saveSignInTime(user), 10000)
      } catch (error) {
        console.log(error)
      }
    }
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState (state) {
    state.uid = null
    state.userDisplayName = null
    state.userAuthEmail = null
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.isSigningOut = false
    state.isAnonymous = null
    state.updateMessagesUnsubscribe = null
  },
  updateSubscriptionEnder (state, value) {
    state.subscriptionEnder = value
  },
  setUpdateOrdersUnsubscribe (state, value) {
    state.updateOrdersUnsubscribe = value
  },
  setUpdateMessageRoomsUnsubscribe (state, value) {
    state.updateMessageRoomsUnsubscribe = value
  },
  setUpdateMessagesUnsubscribe (state, value) {
    state.updateMessagesUnsubscribe = value
  }
}

export const actions = {
  setupAuthStateHandler ({ dispatch, commit, state }) {
    const shouldSetupAuthstate =
      !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
    if (shouldSetupAuthstate) {
      const ender = firebase.auth().onAuthStateChanged(user => {
        commit('updateAuthStatus', user)
      })

      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription ({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut ({ state, commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      if (state.updateWorksUnsubscribe) state.updateWorksUnsubscribe()
      if (state.updateCardsUnsubscribe) state.updateCardsUnsubscribe()
      if (state.updateSumUnsubscribe) state.updateSumUnsubscribe()
      if (state.updateOrdersUnsubscribe) state.updateOrdersUnsubscribe()
      if (state.updateMessageRoomsUnsubscribe) state.updateMessagesRoomsUnsubscribe()
      if (state.updateMessagesUnsubscribe) state.updateMessagesUnsubscribe()
      await firebase.auth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  setUnsubscribeUpdateOrders ({ commit }, value) {
    commit('setUpdateOrdersUnsubscribe', value)
  },
  setUnsubscribeUpdateMessageRooms ({ commit }, value) {
    commit('setUpdateMessageRoomsUnsubscribe', value)
  },
  setUnsubscribeUpdateMessages ({ commit }, value) {
    commit('setUpdateMessagesUnsubscribe', value)
  }
}

async function saveSignInTime (user) {
  await database.userCollection().update(user.uid, {
    lastSignInTime: new Date()
  })
}
