import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/sign-in'
    },
    {
      path: '/tmp',
      name: 'tmp',
      component: () =>
        import('@/views/TmpWithModal')
    },
    {
      path: '/tmp/:id1',
      name: 'tmp_detail',
      props: true,
      component: () =>
        import('@/views/TmpWithModal')
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      props: true,
      component: () =>
        import('@/views/SignIn')
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      component: () =>
        import('@/views/Tutorial')
    },
    {
      path: '/info',
      name: 'info',
      component: () =>
        import('@/views/Info')
    },
    {
      path: '/create-order',
      name: 'create-order',
      component: () =>
        import('@/views/CreateOrder')
    },
    {
      path: '/create-order/:orderId',
      name: 'check-service-fee-payment',
      component: () =>
        import('@/views/CreateOrder')
    },
    {
      path: '/mypage',
      name: 'mypage',
      component: () =>
        import('@/views/MyPage')
    },
    {
      path: '/mypage/order/:workId',
      name: 'myorder',
      component: () =>
        import('@/views/MyPage')
    },
    {
      path: '/mypage/order/:workId/payment',
      name: 'check-advanced-money-payment',
      component: () =>
        import('@/views/MyPage')
    },
    {
      path: '/mypage/order/:workId/review',
      name: 'order-review',
      component: () =>
        import('@/views/MyPage')
    },
    {
      path: '/terms/:type',
      name: 'terms',
      component: () =>
        import('@/views/Terms')
    }
  ]
})
