const fns = require('date-fns')

/**
 * @type {firebase.FieldValue}
 */
let _FieldValue

/**
 * @type {firebase.firestore.Firestore}
 */
let _firestore

/**
 * @typedef IUserModel
 * @property {string} id
 */

/**
 * @typedef IWorkModel
 * @property {string} id
 */

/**
 * @typedef IHistoryModel
 * @property {string} id
 */

/**
 * @typedef ISecretModel
 * @property {string} id
 */

/**
 * @typedef IAddressModel
 * @property {string} id
 */

/**
 * @typedef IOrderModel
 * @property {string} id
 */

/**
 * @interface BaseCollection
 * @template TModel
 */
class BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('__NONE__')
  }

  /**
   * @returns {string}
   */
  getNewDocId () {
    return this.collection.doc().id
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {TModel}
   */
  getDataFromDocumentData (doc) {
    if (doc.exists) {
      return {
        ...doc.data(),
        id: doc.id
      }
    } else {
      return null
    }
  }

  getAllDataFromDocs (docs) {
    return docs.map((d) => this.getDataFromDocumentData(d))
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  all () {
    return this.collection
      .get()
      .then((q) => q.docs.map(this.getDataFromDocumentData))
  }

  /**
   * @returns {Promise<string[]>}
   */
  allIds () {
    return this.collection.get().then((q) => q.docs.map((item) => item.id))
  }

  /**
   * @param {string} id
   * @returns {Promise<TModel>}
   */
  findById (id) {
    return this.collection.doc(id).get().then(this.getDataFromDocumentData)
  }

  /**
   * @param {TModel} obj
   * @returns {Promise<TModel>}
   */
  add (obj) {
    return this.collection
      .add(obj)
      .then((q) => q.get())
      .then(this.getDataFromDocumentData)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  set (id, obj) {
    return this.collection.doc(id).set(obj)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  update (id, obj) {
    return this.collection.doc(id).update(obj)
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  delete (id) {
    return this.collection.doc(id).delete()
  }

  /**
   * Return unsubscribe function
   * @param {string} id
   * @param {(object: TModel) => void} callback
   * @returns {() => void}
   */
  onUpdated (id, callback) {
    return this.collection.doc(id).onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

/**
 * @extends {BaseCollection<IUserModel>}
 */
class UserCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER')
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {ITrainerModel}
   */
  getDataFromDocumentData (doc) {
    if (doc.exists) {
      return {
        ...doc.data(),
        uid: doc.id,
        id: doc.id
      }
    } else {
      return null
    }
  }

  /**
   * @param {string} id
   * @returns {IUserModel}
   */
  getUserByLineId (id) {
    return this.collection
      .where('lineId', '==', id)
      .get()
      .then((q) => this.getDataFromDocumentData(q.docs)[0])
  }

  async transactionPoint (userId, number) {
    await this.update(userId, { point: _FieldValue.increment(number) })
  }

  completeTutorialAndAddGeolocationPermission (id, geolocationPermission) {
    return this.update(id, {
      completeTutorial: true,
      geolocationPermission: _FieldValue.arrayUnion(geolocationPermission)
    })
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class WorkCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('WORK')
  }
}

/**
 * @extends {BaseCollection<IHistoryModel>}
 * @property {string} _userId
 */
class HistoryCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('HISTORY')
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  async getHistoriesByLastMonth () {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const lastMonthday = new Date(`${year}/${month}/1 00:00:00+09:00`)
    const q = await this.collection
      .where('createdAt', '<=', now)
      .where('createdAt', '>=', lastMonthday)
      .get()
    return this.getAllDataFromDocs(q.docs)
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IHistoryModel[]) => void} callback
   * @returns {() => void}
   */
  onUpdatedWithOptions (_, callback) {
    const query = this.collection
    return query.onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

class PointLogCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('POINT_LOG')
  }
}

/**
 * @extends {BaseCollection<ISecretModel>}
 * @property {string} _userId
 */
class SecretCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('SECRET')
  }
}

class AccessLogCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('ACCESS_LOG')
  }
}

/**
 * @extends {BaseCollection<IAddressModel>}
 * @property {string} _userId
 */
class AddressCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('ADDRESS')
  }
}

class CouponCollection extends BaseCollection {
  /**
   * @param {string} userId
   */
  constructor (userId) {
    super()
    this._userId = userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('USER').doc(this._userId).collection('COUPON')
  }

  getValidCoupon () {
    return this.collection
      .where('isValid', '==', true)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

/**
 * @extends {BaseCollection<IOrderModel>}
 */
class OrderCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('ORDER')
  }

  getOpenedOrder () {
    return this.collection
      .where('status', '==', 'open')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getUncompletedShopOrder () {
    return this.collection
      .where('status', 'in', ['open', 'assigned'])
      .where('orderType', '==', 'shopOrder')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  getMyOrders (uid) {
    return this.collection
      .where('customer', '==', uid)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IOrderModel[]) => void} callback
   * @returns {() => void}
   */
  getMyOrderSnap (uid, callback) {
    const query = this.collection
      .where('customer', '==', uid)
      .where('status', 'in', ['created', 'open', 'assigned', 'delivered', 'done'])
    return query.onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  getOrderByWorkId (workId) {
    return this.collection
      .where('workId', '==', workId)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IOrderModel[]) => void} callback
   * @returns {() => void}
   */
  getOrderSnapByWorkId (workId, callback) {
    const query = this.collection
      .where('workId', '==', workId)
    return query.onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

class MessageRoomCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('MESSAGE_ROOM')
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IMessageRoomModel[]) => void} callback
   * @returns {() => void}
   */
  getMyMessageRoomSnap (uid, callback) {
    const query = this.collection
      .where('customerId', '==', uid)
    return query.onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

class MessageCollection extends BaseCollection {
  /**
   * @param {string} roomId
   */
  constructor (roomId) {
    super()
    this._roomId = roomId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection () {
    return _firestore.collection('MESSAGE_ROOM').doc(this._roomId).collection('MESSAGE')
  }

  onUpdatedWithOptions (callback) {
    return this.collection.orderBy('createdAt').onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

/**
 * The whole database
 */
const database = {
  userCollection: () => new UserCollection(),

  workCollection: () => new WorkCollection(),

  historyCollection: (userId) => new HistoryCollection(userId),

  pointLogCollection: (userId) => new PointLogCollection(userId),

  secretCollection: (userId) => new SecretCollection(userId),

  accessLogCollection: (userId) => new AccessLogCollection(userId),

  addressCollection: (userId) => new AddressCollection(userId),

  couponCollection: (userId) => new CouponCollection(userId),

  orderCollection: () => new OrderCollection(),

  messageRoomCollection: () => new MessageRoomCollection(),

  messageCollection: (roomId) => new MessageCollection(roomId)
}

/**
 * @param {firebase.firestore.Firestore} firestore
 * @param {Object} param1
 * @param {firebase.firestore.FieldValue} param1.FieldValue
 */
const initializeDatabase = (firestore, { FieldValue }) => {
  console.log('initializeDatabase')
  _FieldValue = FieldValue
  _firestore = firestore
}

module.exports = {
  database,
  initializeDatabase
}
