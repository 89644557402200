<template lang="pug">
v-app
  v-main
    router-view
</template>

<script>
import 'normalize.css'
import { createNamespacedHelpers } from 'vuex'
import { database } from '../functions/shared/database'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
export default {
  name: 'App',
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    // eslint-disable-next-line quote-props
    '$route': async function (to, from) {
      if (this.uid) {
        await database.accessLogCollection(this.uid).add({
          uid: this.uid,
          page: to.path,
          from: from.path,
          accessedAt: new Date()
        })
      }
    },
    // eslint-disable-next-line quote-props
    'uid': async function (val, oldVal) {
      if (val && !oldVal) {
        await database.accessLogCollection(val).add({
          uid: val,
          page: this.$route.path,
          from: '',
          accessedAt: new Date()
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "./plugins/meltline.css";

html,
body {
  margin: 0;
  height: 100%;
}
</style>
