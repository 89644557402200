const DEBOUNCE_TIME = 250

const debounce = (_this, func, wait = DEBOUNCE_TIME) => {
  let timeoutId

  // conversion through any necessary as it wont satisfy criteria otherwise
  return (...args) => {
    // clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const context = _this
    timeoutId = setTimeout(() => {
      func.apply(context, args)
    }, wait)
  }
}

export { debounce }
